<template>
  <div class="footer d-flex align-center justify-center">
    <!-- <a href="https://www.facebook.com/at.sriwichai" target="_blank">
      <img class="icon" src="../assets/facebook.png" alt=""
    /></a> -->
    <v-tooltip left color="black">
      <template v-slot:activator="{ on, attrs }">
        <img
          style="width: 50px;"
          src="../assets/line.png"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <span>autochonlatee</span>
    </v-tooltip>
    <a href="mailto:atbochonlatee@gmail.com" target="_blank">
      <img class="icon ml-5 mr-5" src="../assets/gmail.png" alt=""
    /></a>
    <v-tooltip right color="black">
      <template v-slot:activator="{ on, attrs }">
        <img
          style="width: 50px;"
          src="../assets/telephone.png"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <span>061 - 337 - 9069</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  /* background-color: #e7e7e7; */
  width: 100%;
  height: 100px;
}
.icon {
  width: 50px;
  transition: 0.2s;
}
.icon:hover {
  margin-bottom: 10px;
}
</style>
