<template>
  <div class="main">
    <v-parallax dark src="../assets/background.jpg">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="display-1 font-weight-black mb-4 text-uppercase txt">
            Chonlatee Sriwichai Software developer
          </h1>
        </v-col>
      </v-row>
    </v-parallax>
    <v-container class="container-custom">
      <v-row
        no-gutters
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        <v-col class="d-flex align-center center" cols="12" md="6" sm="12">
          <img class="img pa-10" src="../assets/at.jpg" />
        </v-col>
        <v-col class="pa-10 center" cols="12" md="6" sm="12">
          <h1>I am software developer experience in front-end</h1>
          <br />
          <h1 class="text-blue">SKILL</h1>
          <v-divider></v-divider>
          <h2 v-for="(item, index) in skill" :key="index">
            {{ item }}
          </h2>
          <br />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row
        no-gutters
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        <v-col class="d-flex align-center center" cols="12" md="6" sm="12">
          <img class="img pa-10" src="../assets/learn.png" />
        </v-col>
        <v-col class="pa-10 center" cols="12" md="6" sm="12">
          <h1 class="text-blue">EDUCATION</h1>
          <h2>MAE FAH LUANG UNIVERSITY</h2>
          <h2>Bachelor of science | 2016 - 2020</h2>
          <br />
          <h3>Major: Software Engineering</h3>
          <h3>School of Information Technology</h3>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div
        class="pa-10"
        style="text-align:center"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        <h1 class="text-blue">WORK EXPERIENCE</h1>
      </div>
      <v-row
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        <v-col
          class="center"
          cols="12"
          md="6"
          sm="12"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
          v-for="(item, index) in informationCompany"
          :key="index"
        >
          <div class="card-portfolio">
            <p class="text-header">
              {{ item.company }}
            </p>
            <p>
              {{ item.date }}
            </p>
            <p class="ma-0">Position : {{ item.position }}</p>
            <!-- <p>Responsibility : {{ item.responsibility }}</p> -->
          </div>
        </v-col>
      </v-row>

      <div class="divide"></div>

      <v-divider></v-divider>
      <div
        class="pa-10"
        style="text-align:center"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        <h1 class="text-blue">PROJECTS</h1>
      </div>
      <v-row
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        <v-col
          class="center"
          cols="12"
          md="6"
          sm="12"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
          v-for="(item, index) in infoWork"
          :key="index"
        >
          <div class="card-portfolio">
            <p class="text-header">
              {{ item.name }}
            </p>
            <p>About : {{ item.description }}</p>
            <p class="ma-0">Tools : {{ item.tool }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "Home",
  data() {
    return {
      skill: [
        "Vue ( Nuxt js )",
        "React ( Next js )",
        "HTML5",
        "CSS",
        "Javascript",
        "Git",
        "Node js",
        "Line developer",
      ],
      informationCompany: [
        {
          company: "Greenmoons Co., Ltd.",
          date: "Feb 2024 - Present",
          position: "Front end developer",
          responsibility:
            "Develop ecommerce web application and maintenance system",
        },
        {
          company: "DOSETECH Co., Ltd.",
          date: "Sep 2022 - Dec 2023",
          position: "Front end developer",
          responsibility:
            "Develop ecommerce web application and maintenance system",
        },
        {
          company: "Rudy Technology",
          date: "Sep 2021 - Sep 2022",
          position: "Front end developer",
          responsibility:
            "Develop ecommerce web application and maintenance system",
        },
        {
          company: "Infinityplatform",
          date: "Jun 2021 - Jul 2021",
          position: "Front end developer",
          responsibility:
            "Develop novel mobile application by line developer  tools",
        },
        {
          company: "In2it Company",
          date: "Nov 2020 - Apr 2021",
          position: "Front end developer",
          responsibility:
            "Develop services web application and api services for support organization",
        },
      ],
      infoWork: [
        {
          name: "CLS TEST",
          description:
            "This system is about collect data of teacher all the country to analysis for thesis(Ph.D.) of MFU  professor",
          tool: "Nuxt.JS, Node.JS and database (sql)",
        },
        {
          name: "Sudoku game",
          description:
            "This system is about collect data thru Sudoku game for Alzheimer's patient treatment to analysis for thesis(Master's degree)",
          tool: "Nuxt.JS and database (firebase)",
        },
      ],
    };
  },
  created() {
    AOS.init();
  },
};
</script>

<style scoped>
.container-custom {
  max-width: 1024px;
  margin: auto;
}
h1,
h2,
h3,
h4,
h5,
p {
  font-family: "Prompt", sans-serif;
}
.img {
  width: 390px;
  border-radius: 10px;
  transition: 0.3s;
}
.txt {
  -webkit-text-stroke: 1px black;
  color: white;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
}
.cersor {
  cursor: pointer;
}
.text-header {
  font-weight: bold;
  font-size: 18px;
}
.text-blue {
  color: #004aad;
}
.card-portfolio {
  border: 1px solid #afafaf;
  border-radius: 8px;
  padding: 15px;
  transition: 0.3s;
  height: 100%;
}
.card-portfolio:hover {
  border-color: #e1e1e1;
}
.divider {
  margin-top: 10px;
}
.border-bottom {
  border-bottom: 1px solid gray;
}
.divide {
  padding: 40px 0px;
}
@media only screen and (max-width: 959px) {
  .center {
    justify-content: center;
    text-align: center;
  }
}
@media only screen and (max-width: 408px) {
  .maximage {
    width: 100%;
  }
}
</style>
