<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-divider></v-divider>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "./component/Footer.vue";
import "../src/assets/css/style.css";
export default {
  components: { Footer },
  name: "App",
};
</script>
<style scoped>
.main {
  height: 100%;
  overflow: hidden;
}
</style>
